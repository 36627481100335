import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'            //引入ElementUI
import 'element-ui/lib/theme-chalk/index.css' //导入样式
//引入quill-editor编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// import axios from 'axios'                     //引入axios
import router from './router'                 //引入router
import service from './service'
Vue.prototype.service = service                   // 挂载到原型上，可以全局使用
Vue.use(ElementUI)                            //使用
Vue.use(VueQuillEditor)
//实现quill-editor编辑器拖拽上传图片
import * as Quill from 'quill'
import { ImageDrop } from 'quill-image-drop-module'
Quill.register('modules/imageDrop', ImageDrop)
 
//实现quill-editor编辑器调整图片尺寸
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)

import moment from 'moment'
Vue.prototype.$moment = moment

import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll)

//全局引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts=echarts

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
	// 跳转后返回页面顶部
  router.afterEach(() => {
    window.scrollTo(0,0);
})