import axios from 'axios'
import {getToken} from '@/utils/setToken.js'
import { Message } from 'element-ui'
const service = axios.create({
    baseURL:'/api', //baseURL会自动加在请求地址上
    timeout:5000
})
// 添加请求拦截器
service.interceptors.request.use((config)=>{
    // 在请求之前做些什么（获取并设置token）
    config.headers['satoken'] = getToken('satoken')
    return config
},(error)=>{
    return Promise.reject(error)
})
 
// 添加响应拦截器
service.interceptors.response.use((response)=>{
    // 在响应数据做些什么（获取并设置token）
    let {code, message} = response.data
    if(code !== 200 && message!=null){
        Message({message: message || 'error',type:'warning'})
    }
    let {msg} = response.data
    if(code !== 200 && msg!=null){
        Message({message: msg || 'error',type:'warning'})
    }
     return response
},(error)=>{
    return Promise.reject(error)
})
export default service