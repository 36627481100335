<template>
  <div id="app"><router-view></router-view></div>
</template>

<script>
// import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
  }
}
</script>

<style >
* {
  margin: 0;
  padding: 0;
}
body {
  /* background-color: #F8F8F8; */
  /* background-color: #e0f8f6; */
  /* background-color: #ebf3fcb0; */
  background-color: #f1f6fc;
  
}

html,
body {
  height: 100%;
  width: 100%;
}

#app {
  height: 100%;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

/* 设置浏览器滚动条的样式 */ 
::-webkit-scrollbar { 
 width: 0px; 
 } 
 /* 滚动槽 */ 
 ::-webkit-scrollbar-track { 
 -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3); 
 border-radius: 10px; 
 } 
 /* 滚动条滑块 */ 
 ::-webkit-scrollbar-thumb { 
 border-radius: 10px; 
 background: rgba(0, 0, 0, 0.1); 
 -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); 
 } 
 ::-webkit-scrollbar-thumb:window-inactive { 
 background: rgba(255, 0, 0, 0.4); 
 }

 /* @media screen and (min-width: 1200px) {
    .container {
        width: 1127px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media screen and (min-width: 922px) and (max-width:1199px) {
    .container {
        width: 933px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media screen and (min-width: 768px) and (max-width:922px) {
    .container {
        width: 723px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media screen and (max-width: 768px) {
    .container {
        width: 100%;
        padding: 0px 10px 0 10px !important;
        box-sizing: border-box;
    }
} */

</style>
